import { db } from "@kanpla/services";
import {
  getLocationHostname,
  getPartnerUrl,
  getSupplier,
} from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { GetServerSidePropsContext } from "next";
import { useEffect } from "react";
import { useSessionstorageState } from "rooks";
import CompanySuppliers from "../components/CompanySuppliers";
import LandingApp from "../components/LandingApp";

interface Props {
  supplier?: Supplier;
}

const Index = ({ supplier }: Props) => {
  const [, setSupplier] = useSessionstorageState<Supplier>("storage-supplier");
  useEffect(() => {
    if (supplier) setSupplier(supplier);
  }, [supplier?.id]);

  return (
    <>
      <CompanySuppliers supplier={supplier} />
      <LandingApp />
    </>
  );
};

export const getServerSideProps = async ({
  req,
}: GetServerSidePropsContext) => {
  try {
    const parseResult = getLocationHostname(req?.headers?.host);
    const url = getPartnerUrl({ hostname: parseResult });

    const supplier =
      (await getSupplier({
        db,
        url,
      })) || {};

    return {
      props: {
        supplier,
      },
    };
  } catch (e) {
    console.error(e);
    return { props: {} };
  }
};

export default Index;
